import { MrbBaseRouteStore } from "mrb/core";
import { PodcastService, RssSyncService, PodcastFileStreamService } from "common/services";
import { createBaasicAuthenticatedMediaVaultUrl } from "mrb/baasic";

class PodcastEpisodeListRouteStore extends MrbBaseRouteStore {
    constructor(rootStore) {
        super(rootStore);
        this.podcastService = this.rootStore.createApplicationService(PodcastService);
        this.rssSyncService = this.rootStore.createApplicationService(RssSyncService);
        this.podcastFileStreamService = this.rootStore.createApplicationService(PodcastFileStreamService);
    }

    async getPodcast(id) {
        const response = await this.podcastService.get(id, { embed: "parentPodcast,podcastFeed" });
        return response.data;
    }

    async getEpisodes(podcastId, params) {
        const response = await this.podcastService.getAllEpisodes(podcastId, params);
        return response.data;
    }

    async hlsEncodePodcastEpisodes(options) {
        await this.rssSyncService.hlsEncodePodcastEpisodes(options);
    }

    async hlsEncodePodcasts(options) {
        await this.rssSyncService.hlsEncodePodcasts(options);
    }

    async syncPodcasts(data) {
        await this.rssSyncService.syncPodcasts(data);
    }

    async editPodcastEpisodeTitle(id, resource) {
        await this.podcastService.editPodcastEpisodeTitle(id, resource);
    }

    createPodcastEpisodeAudioFileUrl(podcastEpisodeId) {
        return createBaasicAuthenticatedMediaVaultUrl(
            this.rootStore,
            this.podcastFileStreamService.routeService.getPodcastEpisodeAudioFile(podcastEpisodeId)
        );
    }

    async syncPodcastsBackground(data) {
        await this.rssSyncService.syncPodcastsBackground(data);
    }
}

export default PodcastEpisodeListRouteStore;
