import { action, computed, observable, makeObservable } from "mobx";
import { MrbBasePreviewViewStore } from "mrb/core";
import { MrbModalParams } from "mrb/common/models";
import { localizationService } from "common/localization";
import { forEach, find, some, isNil, filter, includes } from "lodash";
import { DownloadRequest } from "common/models";

class NakiNigunAlbumTrackListFilter {
    @observable search = "";
    @observable hlsAvailable = null;
    @observable mp3Available = null;

    constructor() {
        makeObservable(this);
    }

    @action.bound
    setSearch(search) {
        this.search = search;
    }

    @action.bound
    setHlsAvailable(value) {
        this.hlsAvailable = value;
    }

    @action.bound
    setMp3Available(value) {
        this.mp3Available = value;
    }
}

class NakiNigunAlbumTrackListViewStore extends MrbBasePreviewViewStore {
    @observable songs = [];
    @observable.ref filter = new NakiNigunAlbumTrackListFilter();

    @computed get filteredSongs() {
        if (this.filter.search === "" && this.filter.hlsAvailable === null && this.filter.mp3Available === null) {
            return this.songs;
        }
        return filter(this.songs, (song) => {
            if (
                this.filter.hlsAvailable !== null &&
                !((this.filter.hlsAvailable && song.hlsStreamUrl) || (!this.filter.hlsAvailable && !song.hlsStreamUrl))
            ) {
                return false;
            }
            if (
                this.filter.mp3Available !== null &&
                !(
                    (this.filter.mp3Available && song.audioStreamUrl) ||
                    (!this.filter.mp3Available && !song.audioStreamUrl)
                )
            ) {
                return false;
            }
            if (
                this.filter.search !== "" &&
                this.filter.search != null &&
                !includes(song.title.toLowerCase(), this.filter.search.toLowerCase())
            ) {
                return false;
            }

            return true;
        });
    }

    @computed get isSyncing() {
        if (this.songs.length === 0) {
            return false;
        }
        return some(this.songs, (song) => !isNil(song.syncTask));
    }

    @computed get coverImageUrl() {
        if (this.item && this.item.coverImageId) {
            return this.rootStore.coverImageFactory.createNakiNigunAlbumCoverImageUrl(this.item.coverImageId, 150, 150);
        }

        return null;
    }

    constructor(rootStore, { routeStore }) {
        const albumId = rootStore.routerStore.routerState.params.id;
        super(rootStore, {
            id: albumId,
            layoutActions: {
                generate: {
                    onClick: () => this.openGeneratePopupForAlbum(),
                    label: "ADMINISTRATION.NAKI_NIGUN.SONGS.HEADER.BUTTONS.GENERATE",
                    className: "c-btn c-btn--base c-btn--primary",
                },
            },
        });
        makeObservable(this);
        this.routeStore = routeStore;

        this.generateAudioFilesModal = new MrbModalParams();
        this.editAlbumTrackModal = new MrbModalParams();

        this.openGeneratePopupForSong = this.openGeneratePopupForSong.bind(this);
        this.openGeneratePopupForAlbum = this.openGeneratePopupForAlbum.bind(this);
        this.openEditAlbumTrack = this.openEditAlbumTrack.bind(this);
        this.goToUploadAlbumTrack = this.goToUploadAlbumTrack.bind(this);

        this.reaction(
            () => this.rootStore.contentEncoderSyncStore.albumTrackTasks,
            async (songTasks) => {
                const isUpdated = await this._assignSyncTask(songTasks, this.songs);
                if (isUpdated) {
                    this.getSongs(this.id);
                }
            }
        );
    }

    async onInit() {
        await Promise.all([this.getSongs(this.id), this.initializeResource()]);
    }

    get(id) {
        return this.routeStore.getAlbum(id);
    }

    @action.bound
    async getSongs(albumId) {
        try {
            const songs = await this.routeStore.getSongs(albumId);
            this._assignSyncTask(this.rootStore.contentEncoderSyncStore.albumTrackTasks, songs);
            this.setSongs(songs);
        } catch (err) {
            this.rootStore.notificationStore.error(
                localizationService.t("ADMINISTRATION.NAKI_NIGUN.SONGS.ERROR.FETCH_SONGS_GENERIC"),
                err
            );
        }
    }

    @action.bound
    async syncNewSongs() {
        try {
            await this.routeStore.syncNakiNigunAlbumsBackground({ ids: [this.id] });
            this.rootStore.notificationStore.info(localizationService.t("ADMINISTRATION.NAKI_NIGUN.SONGS.SYNC.START"));
        } catch (err) {
            this.rootStore.notificationStore.error(
                localizationService.t("ADMINISTRATION.NAKI_NIGUN.SONGS.ERROR.SYNC_NEW_SONGS_GENERIC"),
                err
            );
        }
    }

    @action.bound
    async processHlsEncodingForAlbumTrack(albumTrackId, processHls, processMp3) {
        const data = {
            ids: [albumTrackId],
            processHls: processHls,
            overwriteHls: processHls,
            processMp3: processMp3,
            overwriteMp3: processMp3,
        };
        try {
            await this.routeStore.hlsEncodeAlbumTracks(data);
        } catch (err) {
            this.rootStore.notificationStore.error(
                localizationService.t("ADMINISTRATION.NAKI_NIGUN.SONGS.ERROR.ENCODE_SONG_GENERIC"),
                err
            );
        }
        this.rootStore.contentEncoderSyncStore.resetSync();
        this.generateAudioFilesModal.close();
    }

    @action.bound
    async processHlsEncodingForAlbum(processHls, processMp3) {
        const data = {
            ids: [this.id],
            processHls: processHls,
            overwriteHls: processHls,
            processMp3: processMp3,
            overwriteMp3: processMp3,
        };
        try {
            await this.routeStore.hlsEncodeAlbums(data);
        } catch (err) {
            this.rootStore.notificationStore.error(
                localizationService.t("ADMINISTRATION.NAKI_NIGUN.SONGS.ERROR.ENCODE_ALBUM_GENERIC"),
                err
            );
        }
        this.generateAudioFilesModal.close();
    }

    @action.bound
    async editAlbumTrackTitle(albumTrackId, title) {
        const data = { title: title };
        try {
            await this.routeStore.editAlbumTrackTitle(albumTrackId, data);
        } catch (err) {
            this.rootStore.notificationStore.error(
                localizationService.t("ADMINISTRATION.NAKI_NIGUN.ARTIST.PREVIEW.ALBUM.SONG_LIST.ERROR.GENERIC_MESSAGE"),
                err
            );
        }
        this.editAlbumTrackModal.close();
    }

    openEditAlbumTrack(albumTrack) {
        this.editAlbumTrackModal.open({
            id: albumTrack.id,
            title: albumTrack.title,
            editAlbumTrackTitle: this.editAlbumTrackTitle,
        });
    }

    openGeneratePopupForSong(song) {
        this.generateAudioFilesModal.open({
            id: song.id,
            artworkUrl: song.artworkUrl,
            title: song.title,
            duration: song.duration,
            hlsStreamUrl: song.hlsStreamUrl,
            audioFileBitrate: song.audioFileBitrate,
            audioStreamUrl: song.audioStreamUrl,
            audioFileSize: song.audioFileSize,
            processHlsEncodingForSingleEntity: this.processHlsEncodingForAlbumTrack,
        });
    }

    openGeneratePopupForAlbum() {
        this.generateAudioFilesModal.open({
            artworkUrl: this.coverImageUrl,
            title: this.item.title,
            subtitle: this.item.artist.name,
            processHlsEncodingForAllEntities: this.processHlsEncodingForAlbum,
        });
    }

    _assignSyncTask(syncTasks, songs) {
        let isUpdated = false;
        forEach(songs, (song) => {
            const newSyncTask = find(syncTasks, (syncTask) => song.id === syncTask.trackId);
            if (!isNil(song.syncTask) && isNil(newSyncTask)) {
                isUpdated = true;
            }
            song.syncTask = newSyncTask;
        });

        return isUpdated;
    }

    @action.bound
    setSongs(songs) {
        this.songs = songs;
    }

    updateAlbumTrackTitle(songId, newTitle) {
        const song = find(this.songs, (song) => song.id === songId);
        if (song) {
            song.title = newTitle;
        }
    }

    downloadAlbumTrack = (item) => {
        if (item && item.audioStreamUrl) {
            var downloadRequest = new DownloadRequest(
                item.id,
                item.title,
                item.artworkUrl,
                this.routeStore.createAlbumTrackAudioFileUrl(item.id),
                item.duration,
                item.audioFileSize
            );
            this.routeStore.rootStore.downloadManagerStore.addItemToQueue(downloadRequest);
        } else {
            return null;
        }
    };

    goToUploadAlbumTrack() {
        this.rootStore.routerStore.goTo("master.administration.naki-nigun.artist.album.tracks.create", { id: this.id });
    }
}

export default NakiNigunAlbumTrackListViewStore;
